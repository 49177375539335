@import "../../../../node_modules/rsuite/dist/styles/rsuite-default.css";

.bg-container-resell {

    & .rs-modal-content {
        min-height: 43rem;
        background: #F3F3F3;
        max-width: 72rem;
        overflow: auto;
    }

    &.rs-modal-wrapper {
        overflow: auto;
        height: 55rem;



    }

    & .MuiInputBase-root {
        width: 9rem !important;
    }


    display: flex !important;
    justify-content: center;


}


// .rs-modal-wrapper {
//     overflow: auto !important;
// }

.drop-down-refund {
    width: 100% !important;

    background-color: none;


    & .rs-dropdown-toggle {
        width: 100% !important;
        height: 35px;
        text-align: left;
        background-color: white;

    }


}

.rs-dropdown-menu {
    width: 100% !important;
    text-align: left;
    background-color: #ffffff;
    border-radius: 0px !important;

}



.resell-confirmation {
    border: 2px solid white;
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
    width: 40%;
    min-width: 400px;
    min-height: 157px;
    margin-top: 50px;
    background-color: white;;

}

.c-button{
    padding: 8px;
gap: 8px;
width: 121px;
height: 38px;
background: #47B26C;
border-radius: 16px;
font-family: 'Proxima Nova';
font-style: normal;
font-weight: 600;
font-size: 16px;
color: #FFFFFF;

margin: 20px;
}

.resell-confirmation-para{
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
}

.cancel-button{
    background-color: grey;
}

.bg-container-confirmation {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    min-height: 500px;
}



.rs-picker-menu {


    &.fade {
        &.in {
            &.placement-bottom-start {
                &.rs-picker-select-menu {
                    z-index: 2000;
                }
            }
        }
    }

    
    

}

.my-modal{
    z-index: 3000;
}

.payment-error-container{
    display: flex;
    align-items: center;
    margin-left: 1rem;
    height: 20px;
    margin-top: 1rem;
}

.alert-image{
    height: 20px;
    width: 20px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}