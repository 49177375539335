.boxContainer {
  background: #fffafa;
  margin-top: 40px;
  width: 406px;
  height: 100px;
  box-shadow: 0px 4px 4px 2px rgba(207, 207, 207, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  .titleBox {
    font-size: 16px;
    font-weight: 600;
  }
  .insideBoxContainer {
    color: #6d6d6d;
    display: flex;
    align-items: center;

    img {
      width: 30px;
      cursor: pointer;
    }

    div {
      display: flex;
      // flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      margin-left: 20px;
      width: 90px;
      height: 38px;
      background: #ffffff;
      border-radius: 34px;
      cursor: pointer;

      img {
        width: 22px;
      }
    }
  }
}

.rebidReasonSelector {
  margin-top: 20px;
  .rs-dropdown {
  }

  .main-dropDown-bid {
    > .rs-dropdown-toggle {
      display: flex;
      border-radius: 8px;
      min-width: 332px;
      // height: 16px;
      color: #a7a7a7;
      border: 1px solid #d2d2d2;
      &:hover {
      }
    }
  }

  .sub-dropDown-bid {
    > .rs-dropdown-toggle {
      display: flex;
      min-width: 332px;
      color: #000000;
      &:hover {
        background-color: white;
      }
    }
  }
  .rs-dropdown-menu {
    width: 332px;

    .rs-dropdown-menu {
      box-shadow: none;
      border-radius: none;
      padding-bottom: 10px;

      .rs-dropdown-item-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .rs-dropdown-item-disabled .rs-dropdown-item-content {
        opacity: 1;
        cursor: default;
      }
    }
  }
}
/* CustomCheckboxStyles.css */
#checkBoxColor.customCheckboxRoot {
  font-size: 24px !important;
  border-radius: 6px !important;
}

#checkBoxColor.customCheckboxRoot.Mui-checked {
  color: #0A7AFF !important;
}

.selectedRow {
  background-color: #f2f2f2; 
  background-color: #EBF4FF;
  border: 1px solid #EBF4FF;
}
.rounded-div {
  border-radius: 10px;
}

