.pending-inventory-header {
  margin-top: 15px;

  &.card {
    padding-bottom: 15px;
  }

  &.marginFranchiseCard {
    margin-top: 2px;
  }
}