.MuiTableRow-root.Mui-selected {
    background-color: #FBEEED !important;
}

.headingDrawerSHD {
    padding: 10px 5px 10px 20px;
    background-color: #212529;
    font-size: 16px;
    color: white !important;
    font-family: 'ProximaNovaSemibold';
}

.buttonDelivery {
    border: 1px solid rgba(218, 33, 40, 0.66);
    background-color: #FBEEED;
    color: #DA2128;
    padding: 5px 15px;
    border-radius: 6px;
    font-size: 12px;
}

.buttonImage {
    border: 1px solid rgba(52, 27, 199, 0.66);
    background-color: #dcdbf5;
    color: #2e31dd;
    padding: 5px 15px;
    border-radius: 6px;
    font-size: 12px;
}

.starBorderOutlined {
    color: #f4b400 !important;
}

.table-drawer_SHD {
    z-index: 1000;
    min-width: 0;
    width: 100vw;
    height: 1300px;
    min-width: 550px !important;
    // height: inherit;
    align-self: stretch;
    background-color: white;
    transition: all .5s ease-out;
    position: absolute;
    display: none;
    right: 0;
    margin-right: 0px;
    padding-bottom: 0px 5px 20px 5px;
    margin-top: 100Vh;
    // display: none;
    // box-shadow: 1px 2px 10px 2px rgba(0, 0, 0, 0.11);
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;

    &.openNew {
        min-width: 400px !important;
        height: 100%;
        min-height: 160vh;
        transition: all 10s ease-out;
        display: block;
        margin-top: 20px;
    }

    &.openSHD_new {
        display: block;
        position: absolute;
        margin-top: -941px ;
        // margin-right: 0px;
        transition: all .5s ease-out;
        // display: block;

    }

    &.openSHD_new_live {
        display: block;
        position: absolute;
        margin-top: 0px ;
        // margin-right: 0px;
        transition: all .5s ease-out;
        // display: block;

    }
}


// .openSHD_new {
//     display: block;
//     position: absolute;
//     margin-top: -913px;
//     // margin-right: 0px;
//     transition: all .5s ease-out;
//     // display: block;

// }

.bg-container-dialog{
    padding: 20px;
    p{
        margin:10px 0px;
        color: red;
        font-weight: 500;
    }
    .container-datepicker{
        width: 100%;
        & .rs-picker-toggle-wrapper{
            width: 100%;
            margin-bottom: 10px;
        }
    }
    .select-date{
        
    }
    textarea{
        width: 100%;
        height: 100px;
        margin-bottom: 20px;
        resize: none;
        padding: 12px;
        border-radius: 12px;
    }
    p{
        margin: 0px;
    }
    .button-container{
        display: flex;
        justify-content: center;
        align-content: center;

    }
    .button{
        padding: 8px 32px 8px 32px;
        border-radius: 12px;
        font-weight: bold;
        color: white;
        width: 134px;
    }
    .cancel-button{
        background: #E59342;
        margin-right: 32px;
    }
    .reschedule-button{
        background: #47B26C;
    }
}
