.assign-runner-filter-container{
  .city-dropdown-container{
    margin-left: 0;
    margin-right: 10px;
  }
  .from-date{
    margin-right: 10px;
    p{
      margin-left: 0 !important;
    }
  }
}