.refundButtonContianer {
    display: flex;
    justify-content: flex-end;
}

.refundButtonContianer button {
    padding: 8px 24px;
    border-radius: 28px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
    background-color: #DC8C3E;
    margin-right: 25px;
    margin-top: 10px;

}

.priorityButton{
    width: 100%;
    max-width: 135px;
    background-color: #0C84F3;
    color: #FFFFFF;
    border-radius: 11px;
    height: 2.4rem;
    font-size: 17px;
}

.prioritiedButton{
    width: 100%;
    max-width: 135px;
    background-color: #333333;
    color: #FFFFFF;
    border-radius: 11px;
    height: 2.4rem;
    font-size: 17px;
    cursor: text !important;
}

.undoButton{
    width: 100%;
    max-width: 135px;
    color: #0C84F3;
    border-radius: 11px;
    height: 2.4rem;
    font-size: 17px;
    background: none;
}