.model-deep-filter{
    border-radius: 28px;
}
.simple-model{
    & .MuiDialog-paperFullWidth{
        min-height: 519px !important;
    }
}
body {
    & .rs-picker-date-menu{
        z-index: 3000;
    }
    // font-family: inherit !important;
    // letter-spacing: inherit !important;
    * {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
    *, *::before, *::after {
        box-sizing: border-box;

    }
    font-family: inherit;
}

.no-scroll-lock {
    overflow: visible !important;
  }