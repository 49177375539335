.table-container{
  display: table;
  overflow-x: hidden;
  width: 100%;

.table-wraper {
    background-color: white;
    width: 100%;
    overflow-x: auto;
    display: table-cell;

}
    .table-drawer .MuiDrawer-paper {
      // min-width: 0;
      width: 0;
      height: 0;
      align-self: stretch;
      // background-color: #F7F7F7;
      background-color: #ffffff;
      // transition: all 10s ease-out;
      position: relative;
      right: 0;
      // display:none;
      // box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.11);
      // .MuiDrawer-paper{
        min-width: 420px !important;
        // height: 60vh;
        // transition: all 10s ease-out;
        display:table-cell;
        border-right: 1px solid #e0e0e0;
        z-index: 0;
        transform: none;
        transition: transform 500ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        // margin-top: 20px;
      // }
    }

    .link {
      text-decoration: underline;
      cursor: pointer;
      display: inline-block;
    }
  
    &.marginTop {
      margin-top: 22px;
    }
  
    &.marginTopFranchise {
      margin-top: 2px;
    }
  
    &.drawerStyles {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: calc(100% - 250px)
    }
  
    &.sort {
      cursor: pointer;
    }
  
    .MuiTableCell-sizeSmall {
      padding: 6px 8px 6px 8px;
    }
  
    .MuiTable-root {
      font-family: inherit;
  
      .MuiTableHead-root {
        th {
          font-size: 13px;
        }
      }
  
      .MuiTableBody-root {
        td {
          font-size: 11px;
        }
      }
  
      .MuiTableHead-root {
        box-shadow: $shadow;
  
        .MuiTableRow-root .MuiTableCell-root {
          line-height: 1rem;
        }
      }
    }
  
    .table-paginator {
      padding: 20px 15px;
      overflow: auto;
    }
}

.call-header {
  border-bottom: 1px solid
  rgba(224, 224, 224, 1);
  font-family: 'ProximaNovaSemibold';
  padding: 0px 0px 0px 14px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  font-size: 14px;
}

.table-drawer {
&.disable {
display: none;
}
}

.MuiDrawer-docked {
display: table-cell;
background-color: #ffffff;
box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.11);
}