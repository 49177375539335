.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
  }
  
  .table th {
    padding: 20px;
    color: #004e86;
  }
  
  .table td {
    padding: 20px;
    background-color: #377d6c;
    text-align: center;
    color: #ffffff;
  }
  
  .table th {
    background-color: #ddebf9;
  }
  
  .table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .table tr:hover {
    background-color: #e2e2e2;
  }
  
  .table th:first-child,
  .table td:first-child {
    border-left: none;
  }
  
  .table th:last-child,
  .table td:last-child {
    border-right: none;
  }
  
  .table tbody tr {
    -webkit-border-radius: 25px;
    border-radius: 25px;
  }
  
  .table tbody tr:first-child {
    -webkit-border-radius: 10px 10px 0 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .table tbody tr:last-child {
    -webkit-border-radius: 0 0 10px 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  