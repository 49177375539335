.modalclosebutton {
  top: 8px;
  color: #9e9e9e;
  right: 8px;
  position: absolute;
}

.viewDocs {
  color: #1ea8d8;
  cursor: pointer;
  text-decoration: underline;
}

.docstatustext {
  color: #1ea8d8;
}

.carousel .slide .legend {
  border-radius: 10px;
  background: #000;
  color: #fff;
  font-size: 12px;
  text-align: center;
}