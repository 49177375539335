.table-wraper {
  background-color: white;
  overflow-x: auto;

  .link {
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
  }

  &.marginTop {
    margin-top: 22px;
  }

  &.marginTopFranchise {
    margin-top: 2px;
  }

  &.drawerStyles {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: calc(100% - 250px)
  }
  .custombtn1 {
    width:100%;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 10px;
    height: 20px;
    margin-right: -55px;
  }
  .custombtn2 {
    width:80%;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 10px;
    height: 20px;
  }
  &.sort {
    cursor: pointer;
  }

  .MuiTableCell-sizeSmall {
    padding: 6px 8px 6px 8px;
  }

  .MuiTable-root {
    font-family: inherit;

    .MuiTableHead-root {
      th {
        font-size: 13px;
      }
    }

    .MuiTableBody-root {
      td {
        font-size: 11px;
      }
    }

    .MuiTableHead-root {
      box-shadow: $shadow;

      .MuiTableRow-root .MuiTableCell-root {
        line-height: 1rem;
      }
    }
  }

  .table-paginator {
    padding: 20px 15px;
    overflow: auto;
  }
}

.payment-action-icon {
  width: 15px;
  cursor: pointer;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: #F4F7FD ;
}

td p {
  margin: 0;
}

.action-icon {
  width: 15px;
  cursor: pointer;
}

.MuiDrawer-paperAnchorRight {
  left: auto;
  width: 300px;
  right: 0px;
  height: 100vh;
}

.customerDetails {
  border: 1px solid black;
  margin-top: 20px;
}

.dividerWidth {
  width: 100%;
}

.tableCellHead {
  font-family: 'ProximaNovaSemibold';
}

.oemSupply{
  background: linear-gradient(90deg, #F1F8FF -4.37%, #FFEAFD 28.76%, #FFEAEA 55.37%, #FFFAF1 99.89%);

  &:hover{
    background: linear-gradient(90deg, #FEE 0%, #DDEFBB 100%);
  }
}

.coloredStatus {
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 16px;
  width: fit-content;

  &.COMPLETED{
      color: #196834;
      background:  #DAF2DD;
  }
  &.FAILED{
      color: #DA2128;
      background:#FFD4D6;
  }
  &.PENDING{
      color: #B96716;
      background: #FFE1C5;
  }
  &.REFUNDED{
      color: #E8593B;
      background: #FFF5F3;
  }


}