
.diy-tag{
    height: 24px;
    border-radius: 4px;
    font-family: "ProximaNovaSemibold";
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    padding: 4px 8px;
    box-sizing: border-box;
    width: fit-content;
    text-align: center;
    color: #7A6CA0 !important;
    background: #E1DDF0;
}

.DIY{
    background: #E1DDF0;
    color: #7A6CA0 !important;
    width: 55px;
    
    
}
.ADIY{
    background: #D2E9F6;
    color: #486A9E !important;
    width: 55px;
}
.OEM_ADIY{
    background: #DDEBE0;
    color: #276229 !important;
    width: fit-content;
}
.random{
    width: 55px;
}

.statusPremium{
text-align: center;
padding: 2px 8px;
font-weight: 600;
font-size: 8px;
max-width: 75px;
width: 75px;
color: #fff !important;
border-radius: 8px;
background: linear-gradient(90deg, #9400D3 0%, #4B0082 100%);
box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.10);
}

