.draggable-component {
  position: fixed;
  right: 0;
  z-index: 9999;
  /* Ensure it stays on top of everything */
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: grab;
  width: 260px;
  border-radius: 6px 0px 0px 6px;
  overflow: hidden;


  .headerCard {
    background: #2B2828;
    padding: 12px 12px;
    color: #E0E0E0;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;

    #leadId {
      color: #FFF;
      font-size: 14px;
    }

    .float-right {
      cursor: pointer;
      font-size: 16px;
      color: #FFF;
    }
  }

  .callTimerCard {
    display: flex;
    width: 259px;
    padding: 16px 65px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    background: #010101;

    .custumerName {
      color: #FFF;
      font-size: 16px;
      font-weight: 600;
    }

    .callTimer {
      color: #2FA700;
      font-size: 24px;
    }
  }

  .statusBox {
    display: flex;
    padding: 6px 10px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    color: #fff;
    background-color: #4D9A3A;
    

    &.AGENT {
      background: #2B2828;
      color: #33CA0E;

      &.DISCONNECTED {
        color: #FF8A00;
        animation: opacityAniD .3s ease backwards 5;
      }

      &.NOANSWER {
        color: #F00;
        animation: opacityAniN .3s ease backwards 5;
      }

      &.CANCEL {
        color: #F00;
        animation: opacityAniC .3s ease backwards 5;
      }

      &.FAILED {

        color: #FF8A00;
        animation: opacityAniF .3s ease backwards 5;
      }
    }

    &.CUSTOMER {
      background: #33CA0E;

      &.CONNECTED {
        background: #33CA0E;
        animation: opacityAni .3s ease backwards 5;
      }

      &.CANCEL {
        background: #F00;
        animation: opacityAniC .3s ease backwards 5;
      }

      &.NOANSWER {
        background: #F00;
        animation: opacityAniN .3s ease backwards 5;
      }

      &.FAILED {
        background: #FF8A00;
        animation: opacityAniF .3s ease backwards 5;
      }

      &.DISCONNECTED {
        background: #FF8A00;
        animation: opacityAniD .3s ease backwards 4;
      }
    }

    @keyframes opacityAni {
     0% {
      opacity: .3;
     }
      100% {
      opacity: 1;
      }
  }
    @keyframes opacityAniD {
     0% {
      opacity: .3;
     }
      100% {
      opacity: 1;
      }
  }
    @keyframes opacityAniN {
     0% {
      opacity: .3;
     }
      100% {
      opacity: 1;
      }
  }
    @keyframes opacityAniC {
     0% {
      opacity: .3;
     }
      100% {
      opacity: 1;
      }
  }
    @keyframes opacityAniF {
     0% {
      opacity: .3;
     }
      100% {
      opacity: 1;
      }
  }
  }
}