.refundButtonContianer {
    display: flex;
    justify-content: flex-end;
}

.refundButtonContianer button {
    padding: 8px 24px;
    border-radius: 28px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
    background-color: #DC8C3E;
    margin-right: 25px;
    margin-top: 10px;

}