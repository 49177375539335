.list_table-wraper {
    background-color: white;
    overflow-x: auto;
  
    .link {
      text-decoration: underline;
      cursor: pointer;
      display: inline-block;
    }
  
    &.marginTop {
      margin-top: 22px;
    }
  
    &.marginTopFranchise {
      margin-top: 2px;
    }
  
    &.drawerStyles {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: calc(100% - 250px)
    }
  
    &.sort {
      cursor: pointer;
    }
  
    .MuiTableCell-sizeSmall {
      padding: 6px 8px 6px 8px;
    }
  
    .MuiTable-root {
      font-family: inherit;
  
      .MuiTableHead-root {
        th {
          font-size: 13px;
        }
      }
  
      .MuiTableBody-root {
        td {
          font-size: 15px;
          line-height: 2rem;
        }
      }
  
      .MuiTableHead-root {
        box-shadow: $shadow;
      }
    }
  
    .table-paginator {
      padding: 20px 15px;
      overflow: auto;
    }
  }
  
  .payment-action-icon {
    width: 15px;
    cursor: pointer;
  }
  
  .MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: #F4F7FD !important;
  }
  
  td p {
    margin: 0;
  }
  
  .action-icon {
    width: 15px;
    cursor: pointer;
  }
  
  .MuiDrawer-paperAnchorRight {
    left: auto;
    width: 300px;
    right: 0px;
    height: 100vh;
  }
  
  .dividerWidth {
    width: 100%;
  }
  
  .tableCellHead {
    font-family: 'ProximaNovaSemibold';
  }
  .address-div{
    width:163px;
    margin-bottom:8px;
  }
  .cont-address button:first-child{
    background-color: #19B720;
  width: 53px;
  height: 18px;
  font-size: 11px;
  font-weight: 600;
  line-height: 11px;
  text-align: left;
  letter-spacing: 0.1em;
  color: white;
  border-radius: 4px;
  gap: 8px;
  margin-right: 5px;
  }

  /* ButtonStyles.css */

//   .cont-address button:nth-child(2){
//   background-color: #257DE3;
//   width: 53px;
//   height: 18px;
//   font-size: 11px;
//   font-weight: 600;
//   line-height: 11px;
//   text-align: left;
//   letter-spacing: 0.1em;
//   color: white;
//   border-radius: 4px;
//   gap: 8px;
// }
.addConfirm-btns button:first-child{
    background-color: #DDDDDD;
    color:#929292,
}
.addConfirm-btns button:nth-child(2){
  background-color: #111328;
  color: #fff;
  margin-left: 21px;
}
