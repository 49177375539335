.dialogWrap {
    .MuiDialogTitle-root{
        padding: 0px 24px !important;
    }

    .MuiDialogContent-root{
        padding: 0px 24px 24px;
        border: 1px solid #d8d2d2;
        margin: 40px 35px 30px;
        border-radius: 6px;
        background-color: #f8f8f8!important;
    }

    .MuiDialogContent-root:first-child{
        padding-top: 0px !important;
    }

    .MuiDialogActions-root{
        justify-content: center !important;
    }
}