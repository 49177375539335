.date-pickerr {
    width: 100%;
  
    .react-datepicker-wrapper,
    .react-datepicker__input-container {
      width: 100%;
    }
  
    .react-datepicker__time-list {
      padding: 0;
      overflow-x: hidden;
    }
  
    .react-datepicker__time-list-item {
      width: 50px;
    }
  
    .react-datepicker__time {
      overflow: hidden;
    }
  
    &.date-picker--interval {
      display: flex;
  
      &>div,
      .react-datepicker-wrapper,
      .react-datepicker__input-container {
        max-width: 170px;
      }
    }
  
    .date-picker__svg {
      width: 14px;
      height: 14px;
      margin: auto 10px;
  
      @include themify($themes) {
        fill: themed('colorText');
      }
    }
  
    .react-datepicker__header {
      background-color: $color-blue;
      border-color: $color-blue;
      border-radius: 0;
    }
  
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker__day-name,
    .react-datepicker__time-name {
      color: #ffffff;
    }
  
    .react-datepicker__navigation--next {
      border-left-color: #ffffff;
  
      &:hover {
        border-left-color: #ffffff;
      }
    }
  
    .react-datepicker__navigation--previous {
      border-right-color: #ffffff;
  
      &:hover {
        border-right-color: #ffffff;
      }
    }
  
    .react-datepicker__day--keyboard-selected {
      background-color: $color-blue;
      transition: all 0.3s;
  
      &:hover {
        background-color: $color-blue-hover;
      }
    }
  
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
      border-bottom-color: $color-blue;
      margin-top: -7px;
    }
  
    .react-datepicker-popper {
      z-index: 1050;
      position: fixed !important;
      top: 49% !important;
      left: 51% !important;
    }
  
    .react-datepicker {
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
      border: none;
    }
  
    .react-datepicker__time-container {
  
      @include themify($themes) {
        @include directify($directions) {
          #{directed('border-left')}: 1px solid themed('colorBorder');
        }
      }
  
      width: 85px !important;
    }
  
    .react-datepicker__time-box {
      width: 85px !important;
    }
  
    .react-datepicker__day--selected,
    .react-datepicker__day--in-range,
    .react-datepicker__time-list-item--selected {
      background-color: $color-blue-hover !important;
    }
  
    .react-datepicker__month-container,
    .react-datepicker__time-list {
      @include themify($themes) {
        background-color: themed('colorBackground') !important;
      }
    }
  
    .react-datepicker__header,
    .react-datepicker__day--selected,
    .react-datepicker__time-list-item--selected,
    .react-datepicker__day--keyboard-selected {
      @include themify($themes) {
        background-color: themed('colorHeaderDataPicker') !important;
        color: themed('colorText');
      }
    }
  
    .react-datepicker__day--disabled {
      @include themify($themes) {
        color: #ccc !important;
      }
    }
  
    .react-datepicker__day--weekend,
    .react-datepicker__day,
    .react-datepicker__time-list {
      @include themify($themes) {
        color: themed('colorText');
      }
    }
  
    .react-datepicker__day:hover,
    .react-datepicker__time-list-item:hover {
      @include themify($themes) {
        background-color: themed('colorHover') !important;
      }
    }
  
  }
  
  .date-picker-input-container {
    width: 100%;
    position: relative;
  
    .date-picker-input {
      width: 100%;
      height: 37px;
      padding-right: 25px;
      padding-left: 5px;
      border: 1px solid hsl(0, 0%, 80%);
    }
  
    .calender-icon {
      position: absolute;
      width: 20px;
      right: 5px;
      top: 8px;
    }
  }