.CFPDiv {
    .MuiCard-root {
      overflow: unset !important;
    }
  
    .card {
      padding-bottom: 0px;
    }
  }
  
  .addpricebtn {
    width: 286px;
    height: 34px;
    border-radius: 6px;
    margin: 20px 0px;
  }
  
  .inputField {
    width: 240px;
    height: 29px;
    background: #ffffff;
    border: 1px solid #eff1f7;
    border-radius: 2px;
    margin-right: 15px;
  }
  
  .innerCard {
    width: 500px;
    background: #f8f8f8 !important;
    border-radius: 16px !important;
    margin-top: -15px !important;
    //   box-shadow: none !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
  }
  .MuiDialog-paper {
    border-radius: 16px !important;
  }
  .dialog {
    margin: 20px 0px !important;
  }
  .headerText {
    font-weight: 600;
    font-size: 18px;
    color: #19865f;
  }
  .amtdiv {
    width: 116px;
    height: 33px;
    background: #c7ddff;
    border-radius: 4px;
    margin-left: -25px;
    color: #5498ff;
    font-size: 20px;
    padding: 0px 20px;
  }
  .editbtn {
    width: 15px;
    margin: 0px 20px;
    cursor: pointer;
  }
  .errorDiv {
    margin: 15px;
    height: 25px;
    background: rgba(255, 216, 117, 0.32);
    border: 1px solid #ffde87;
    box-sizing: border-box;
    border-radius: 2px;
    color: #da2128;
    font-size: 12px;
  }
  .filterOptions{
    display: flex;
  }

  .leadSource{
    background-color: #E0ECFB;
    color: #2660B7;
    padding: 3px;
    border-radius: 7px;
    text-align: center;
    max-width: max-content;
    width: 100%;
    border: 1px solid #2660B7;
}

.advanced-filter-button{
  max-width: 80px;
height: 37px;
background: #FFFFFF;
border: 1px solid #CCCCCC;
border-radius: 6px;
box-sizing: border-box;
padding: 5px 4px;
}
.in-advanced{
  margin-top: 40px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
}
.advanced-dropdown{
  &.MuiAccordion-root.Mui-expanded{
    margin: 2px 0px !important;
  }
  &.MuiPaper-elevation1{
    box-shadow: none !important;
    border: none !important;
  }
  &.MuiAccordion-root:before{
    height: 0px;
  }
 
}
.bg-container-advanced-filter{
  padding:24px 16px !important;
  
}


.rs-picker-daterange-menu{
  z-index: 2000 !important;
  position: relative;

}

.filter-count{
  width: 26px;
  height: 26px;
  padding: 3px;
  background: #498DDF;
  border-radius: 12px;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF;
  font-family: proximaNovaRegular;
}

.anti-body{
  letter-spacing: 0px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.storeIntersted{
  background-color: #FAFF10;
  border: 2px solid #000000;
  border-radius: 6px;
}

.priority-filter{
  padding: 2px 8px;
  border: 1px solid hsl(0,0%,80%);
  background-color: white;
  color: hsl(0,0%,50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35.8px;
  margin-right: 12px;
  max-width: 180px;
  width: 100%;
  margin-left: 10px;
  div{
    > label{
      margin: 0px;
      margin-right: 12px;
      
    }
    > img{
      width: 14px;
      height: 11px;
      margin-right: 8px;
    }
  }
  input{
    height: 15px;
    width: 15px;
    cursor: pointer;

  }
  
}
.priorityImage{
  width: 14px;
  height: 11px;
  margin-right: 10px;
}

.interested-buyer-count{
  min-width: 48px;
  width: fit-content;
  height:32px;
  padding: 0px 4px;
  border-radius: 6px;
  background-color: #F0F5FF;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  > div{
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: -30%;
    right: -25%;
    background-color: #115CED;
    color: white;
    border: 3px solid white;
    min-width: 25px
    
  }
}