
.comment {
    display: flex;
    margin-bottom: 20px;
}

.avatar {
    border-radius: 50%;
    margin-right: 10px;
    height: 40px;
    width: 40px;
}

.comment-text {
    flex: 1;
}

h4 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

p {
    margin: 0;
    font-size: 14px;
    color: #666;
}



#comment-box-container {
    display: flex;
    margin-bottom: 10px;
}


/* Styling for the avatar */
.avatar {
    display: inline-block;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    /* background-color: #007bff; */
    color: #FFFFFF;
    text-align: center;
    line-height: 40px;
    font-weight: 500;
    font-size: 24px;
    margin-right: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Proxima Nova';
    font-style: normal;

}


.close-button{
  padding: 0px !important;
}

.comment-type {
    color: #8C4300;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 11px;
    background: #FFEEDF;
    border-radius: 8px;
    padding: 2px 4px;
    font-weight: 600;
    display: inline-block;


}

.comment-owner {
    margin-bottom: 4px;
    font-size: 20px;
    line-height: 20px;

}



.time-lapse {
    display: flex;
    align-items: center;
    margin-left: 16px;
}

.time {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    display: inline-block;
    color: #BBBBBB;
    margin-left: 20px;
}





.comments-text {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #8F8F8F;
    max-width: 90vw;
    
    overflow-wrap: break-word;
}

.comment-name-time-date-container {
    display: flex;
    
}



.comment-name-container{
    display: flex;
    align-items: center;

}





