.comment-box {
    border: 1px solid #ccc;
    padding: 20px;
}

.comment {
    display: flex;
    margin-bottom: 20px;
}

.avatar {
    border-radius: 50%;
    margin-right: 10px;
    height: 40px;
    width: 40px;
}

.comment-text {
    flex: 1;
}

h4 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

p {
    margin: 0;
    font-size: 14px;
    color: #666;
}



#comment-box-container {
    display: flex;
    margin-bottom: 10px;
}

/* Styling for the comment box */
#comment-box {
    width: 100%;
    height: 100px;
    border: 1px solid #ccc;
    padding: 10px;
    resize: none;
    border-radius: 12px;

}

/* Styling for the "Post" button */
#post-button {
    background: #55B7EF;
    border-radius: 12px;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    width: 100%;

    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 40px;

}

/* Styling for the comment section */
#comment-section {
    margin-top: 20px;
}

/* Styling for the avatar */
.avatar {
    display: inline-block;
    width: 58px;
    height: 58px;
    border-radius: 50%;
    /* background-color: #007bff; */
    color: #FFFFFF;
    text-align: center;
    line-height: 40px;
    font-weight: 500;
    font-size: 24px;
    margin-right: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Proxima Nova';
    font-style: normal;

}


.bg-container-chat .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper {
    padding: 24px !important;
    border-radius: 0px !important;
    overflow-y: auto !important;
    margin-top: 143px !important;
    padding-bottom: 143px !important;
   

}

.close-button{
  padding: 0px !important;
}

.comment-box-and-post {
    width: 100%;
}


.comment-type {
    color: #8C4300;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 11px;
    background: #FFEEDF;
    border-radius: 8px;
    padding: 2px 4px;
    font-weight: 600;
    display: inline-block;


}

.comment-owner {
    margin-bottom: 4px;
    font-size: 20px;
    line-height: 20px;
}


.close-button-container  {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    padding: 0px;

    &.MuiToolbar-gutters{
        padding: 0px;
    }
    

}





.time-lapse {
    display: flex;
    align-items: center;
    margin-left: 16px;
}

.time {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    display: inline-block;
    color: #BBBBBB;
}


.vehicle-number {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #333333;
}

.error-comment {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #DA2128; 
    margin-top: 18px;
    margin-bottom: 18px;
}


.comments-text {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #8F8F8F;
}

.comment-time-date-container {
    display: flex;
}

.chat-button {
    background: none;
    border: none;
  }
  
  .chat-image-container {
    position: relative;
    width: 30px;
    height: 32px;
    display: flex;
    align-items: flex-end;
  }
  
  .chat-image {
    display: block;
    width: 21px;
    height: 21px;
  }
  
  .bubble {
    position: absolute;
    top: 0;
    right: 0;
    width: 18px;
    height: 18px;
    border-radius: 10.5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dc3545;
    color: #ffffff;
    margin: 2px;
    border: 2px solid white;
    padding: 2px;
  }



.avatar--light-0 {
  background: #84649C;
  }
.avatar--light-1 {
  background: #92ACBB;
  }
  
  .avatar--light-2 {
    background: #AD7D62;
  }
  
  .avatar--light-3 {
    background: #7A996F;
  }
  
  .avatar--light-4 {
    background: #3A837A;
  }
  
  .avatar--light-5 {
    background: #37549F;
  }
  
  .avatar--light-6 {
    background: #674141;
  }
  
 .avatar--light-unknown{
  background-color: green;
 }


  /* user-type-colors */
  .type--light-S {
    background-color: #FFEEDF; /* Pink */
    color: #8C4300;
  }

.extend{
  padding: 5px 8px 10px 10px;
}

