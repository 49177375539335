.dialogContent {
    // width: 564px;
}

.inputRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    color: #4A4A4A
}

.date-picker {
    color: #DA2128;
}

.editCardMain {
    width: 564px;
    // height: 800px;
    overflow: visible;
    // z-index: -1;
}

.inputBalance {
    align-items: flex-start;
    padding: 10px 12px;
    width: 286px;
    height: 38px;
    background: #FAFAFA;
    border-radius: 7px;
    margin-left: 5px;
    border: 1px solid #CBCBCB;

    &.availableBalance {
        border: none;
    }

    &.clearanceInputBox {
        border: 1px solid #BCBCBC
    }
}

.limiterrorDiv {
    color: #DA2128;
    width: 286px;
    font-size: 12px;
    margin-top: -15px;
}

.refundButton {
    padding: 12px 36px;
    border-radius: 6px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
    background-color: #D000A2;
    margin-top: 20px;

}

.refundButton:disabled,
.refundButton[disabled] {
    // border: 1px solid #999999;
    background-color: #D0D0D0;
    color: #FFFFFF;
}

// .react-datepicker__header {
//     background-color: green
// }

.walletStatus {
    min-width: 50px !important;
    padding: 5px 10px;
    
    color: white !important;
    border-radius: 6px;

    &.walletActive {
        background-color: #42A61C !important;

    }

    &.walletInactive {
        background-color: #DA2128 !important;

    }
}



.walletDetailContainer {
    background: #FAFAFA;
    padding: 5px 12px;
    border-radius: 12px;
    margin-bottom: 20px;
}

.walletDetailContainer div {
    margin: 10px 10px;
    font-weight: 600;
    font-size: 14px;
}

.walletDetailContainer div span {
    min-width: 150px;
    display: inline-block;

    // background-color: #4DBD74;
    &:nth-child(2) {
        color: #606060;
    }
}

.clearanceBox {
    font-size: 13px;
    display: flex;
    align-items: center;
    height: 42px;
    // justify-content: center;
    margin: 14px 0px;
    background: #FFF0F1;
    border-radius: 12px
}

.infoBox {
    max-width: 259px;
    // max-height: 32px;
    background-color: #FFEEFB;
    padding: 8px 12px;
    border-radius: 24px;
    color: #D000A2;
    margin: auto;
}

.infoBoxDocQC {
    // background-color: #EAC5C5;
    color: #CC0000;
    border-radius: 24px;
    margin-bottom: 10px;
    margin-top: -10px;
    background-color: #FFF0F1;
    padding: 8px 10px;

}

.textarea {
    display: block;
    width: 100%;
    overflow: hidden;
    resize: none;
    min-height: 40px;
    line-height: 20px;
    align-items: flex-start;
    padding: 10px 12px;
    background: #FAFAFA;
    border-radius: 7px;
    border: 1px solid #CBCBCB
}

.textarea[contenteditable]:empty::before {
    content: "Placeholder still possible";
    color: gray;
}
.btn-icon-square {
    width: 15px;
    height: auto;
    margin-left: 10px;
    margin-top: -2px;
    color: #D000A2;
    

}
.walletDetailsContainer {
    background: #FAFAFA;
    padding: 5px 12px;
    border-radius: 12px;
    margin-bottom: 20px;
}

.walletDetailsContainer div {
    margin: 10px 10px;
    font-weight: 600;
    font-size: 14px;
}

.walletDetailsContainer div span {
    min-width: 150px;
    display: inline-block;

    // background-color: #4DBD74;
    &:nth-child(2) {
        color: #606060;
    }
}


.inputRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    color: #4A4A4A
}

.dialogMain {
    // min-height: 600px
}

.dialogContent {

    max-height: 610px;
}


.date-picker {
    color: #DA2128;
}

.editCardMain {
    width: 500px;
    // height: 800px;
    overflow: visible;
    // z-index: -1;
}

.inputBalance {
    align-items: flex-start;
    padding: 10px 12px;
    width: 286px;
    height: 38px;
    background: #FAFAFA;
    border-radius: 7px;
    margin-left: 5px;
    border: 1px solid #CBCBCB;

    &.availableBalance {
        border: none;
    }
}

.limiterrorDiv {
    color: #DA2128;
    width: 286px;
    font-size: 12px;
    margin-top: -15px;
}

.refundButton {
    padding: 12px 36px;
    border-radius: 6px;
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
    background-color: #D000A2;
    margin-top: 20px;

}

.refundButton:disabled,
.refundButton[disabled] {
    // border: 1px solid #999999;
    background-color: #D0D0D0;
    color: #FFFFFF;
}

// .react-datepicker__header {
//     background-color: green
// }

.walletDetailsContainer {
    background: #FAFAFA;
    padding: 5px 12px;
    border-radius: 12px;
    margin-bottom: 20px;
}

.walletDetailsContainer div {
    margin: 10px 10px;
    font-weight: 600;
    font-size: 14px;
}

.walletDetailsContainer div span {
    min-width: 150px;
    display: inline-block;

    // background-color: #4DBD74;
    &:nth-child(2) {
        color: #606060;
    }
}

.infoBox {
    max-width: fit-content;
    // max-height: 32px;
    background-color: #FAF9FF;
    padding: 8px 12px;
    border-radius: 24px;
    color: #D000A2;
    margin: auto;
}

.textarea {
    display: block;
    width: 100%;
    overflow: hidden;
    resize: none;
    min-height: 40px;
    line-height: 20px;
    align-items: flex-start;
    padding: 10px 12px;
    background: #FAFAFA;
    border-radius: 7px;
    border: 1px solid #CBCBCB
}

.textarea[contenteditable]:empty::before {
    content: "Placeholder still possible";
    color: gray;
}