.search-field-container {
    width: 100%;
    position: relative;
    max-width: 300px;
  
    &.with-margin {
      margin: 0 10px;
    }
  
    .search-field {
      width: 100%;
      height: 37px;
      padding-left: 5px;
      padding-right: 30px;
      text-overflow: ellipsis;
      border: 1px solid #CECECE;
    }
  
    .search-icon {
      position: absolute;
      width: 20px;
      right: 5px;
      top: 8px;
    }
  
    .clearable {
      display: inline-block;
      position: absolute;
      right: 40px;
      top: 8px;
      cursor: pointer;
    }
  
    .search-button {
      outline: none;
      padding-left: 8px;
      padding-right: 8px;
      border: 0;
      color: white;
      position: absolute;
      background-color: $color-dark-blue;
      top: 0;
      bottom: 0;
      right: 0;
      margin-top: 1px;
      margin-bottom: 1px;
    }
  }