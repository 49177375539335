/*---- fonts ----*/
@font-face {
  font-family: 'ProximaNovaRegular';
  font-display: swap;
  src: url('../shared/fonts/ProximaNova-Regular.eot');
  src: url('../shared/fonts/ProximaNova-Regular.eot') format('embedded-opentype'),
    url('../shared/fonts/ProximaNova-Regular.woff2') format('woff2'),
    url('../shared/fonts/ProximaNova-Regular.woff') format('woff'),
    url('../shared/fonts/ProximaNova-Regular.ttf') format('truetype'),
    url('../shared/fonts/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
}

@font-face {
  font-family: 'ProximaNovaBold';
  font-display: swap;
  src: url('../shared/fonts/ProximaNova-Bold.ttf') format('truetype')
}

@font-face {
  font-family: 'ProximaNovaSemibold';
  font-display: swap;
  src: url('../shared/fonts/Proxima-Semibold.ttf') format('truetype')
}

main {
  padding: 0;
}

video {
  outline: none;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'ProximaNovaRegular', sans-serif;
  font-size: 13px;
  line-height: 1.6;

  @include themify($themes) {
    color: themed('colorText');
  }
}

* {
  box-sizing: inherit;
}

ul,
ol {
  padding-left: 15px;
  margin-bottom: 0;
}

a {
  color: $color-blue;
  transition: all 0.3s;

  &:hover {
    text-decoration: none;
    color: $color-blue-hover;
  }
}

img {
  width: 100%;
}