.Slider-header {
    height: 5rem;
    background: #111328;
}

.droppedClass {
    border: 1px solid #FFD5D6;
    background-color: #FFD5D6;
    color: #DA2128;
    border-radius: 13px;
    width: 100%;
    text-align: center;
}

.pendingClass {
    border: 1px solid #CECECE;
    background-color: #CECECE;
    color: #4A4A4A;
    border-radius: 13px;
    width: 100%;
    text-align: center;
}

.storeintersted {
    padding: 10px;
    color: #141838;
    border-radius: 6px;
    background-color: #FAFF10;
}
.outer-table{
    overflow-x: auto;
    .table-wraper{
      width: 100%;
    }
}
.bg-lock-section{
  border: 1px solid #6FC978;
  border-radius: 12px;
  box-shadow: 2px 2px 2px 0px #0000001A;
  .table-wraper{
    border-radius: 12px;
  }
  .lock-header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #E1F7D8;
    
    padding: 6px 12px;
    border-radius: 12px 12px 0px 0px;

    
    .lock-image-container{
      display: flex;
      align-items: center;
      > img{
        width: 16px;
        height: 21px;
        margin-right: 12px;
      }
      > p{
        font-size: 18px;
        font-weight: 600;
        color: #268900;
      }
     
    }
    .resend-link-button{
      background-color: #201E1E;
      color: white;
      font-weight: 600;
      padding: 6px 12px;
      border-radius: 8px;
    }
  }
} 

.interested-filter{
    border-radius: 18px;
    padding: 0px;
    margin-bottom: 10px;

}

.dialog-bg-container-sugg{
  .MuiDialog-container{
    > .MuiDialog-paper{
      overflow: hidden;
    }
  }
  
  .bg-container-veh-sugg{
    padding: 20px;
     .sugg-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        
        > p{
            font-weight: bold;
            font-size: 18px;
            color: black;
            
        }
        > button{
            background: none;
            cursor: pointer;
            font-size: 18px;
        }
    }
    .sugg-filter-container{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        > button{
          padding: '8px 20px';
          border-radius: '22px';
          width: 136px;
          height: 36px;
          border-radius: 20px;
          margin-right: 12px;
          font-weight: bold;
          color: white;
        }
        .clear-button{
          margin-right: 15;
          background-color: #a19999;
          border: '1px solid #35AC5E';
        }
        .send-button{
          background-color: #4DBD74;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            width: 20px;
            height: 20px;
            margin-left: 12px;
          }
        }
    }
    .search-container{
        display: flex;
        justify-content: space-between;
        .for-suggest{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          // margin: 0px;
          padding: 0px 12px;
          // div{
          //   max-width: 50%;
          // }
        }
        .pagination-container{
          display: flex;
          width: 500px;
          justify-content: flex-end;
          align-items: center;
          & .MuiTablePagination-root{
            & .MuiTablePagination-toolbar p:first-of-type{
          
              display: none;
            
           }
          }
         
          .MuiTablePagination-input{
            display: none;
          }
        }
    }
    .vehicle-selection-section{
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        overflow:auto; 
        height: 55vh;
        grid-gap: 12px;

    }

    .bg-conatiner-card {
        display: flex;
        align-items: center;
        border: 1px solid #dfdfdf;
        border-radius: 12px;
        width: 283px;
        height: 100px;
        line-height: 12px;
        padding: 12px;
        cursor: pointer;
        // margin-right: 12px;
        // margin-bottom: 12px;
        > img {
          width: 56px;
          min-width: 56px;
          height: 54px;
          margin-right: 12px;
          margin-top: 10px;
          border-radius: 10px;
        }
        position: relative;
        .select-input {
          position: absolute;
          top: 10px;
          cursor: pointer;
        }
        .details-section {
          letter-spacing: 0.3px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          .location{
            margin: 0px;
            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
          }
          .bike-model {
            margin-top: 10px;
            font-size: 13px;
            font-weight: bold;
            color: black;
          }
          .price {
            color: #5313a1;
            font-weight: bold;
            letter-spacing: 1px;
          }
          .minute-details-container {
            display: flex;
            align-items: center;
            max-width: 100%;
            .minute-image {
              margin-right: 5px;
              width: 10px;
              height: 10px;
              width: 8px;
              height: 8px;
            }
      
            > div {
              font-size: 9px;
              margin-right: 5px;
              display: flex;
              align-items: center;
            }
          }
        }
      }

}

}


.purchase-price-input{
  border: none;
  & .MuiInputBase-root{
    & .MuiOutlinedInput-notchedOutline{
      border: none;
    }
  }
  & .MuiOutlinedInput-input{
    padding: 0px;
  }
}