.dialogMain {
    margin: 0 !important;
    // background-color: red;
}

.infoContainer {
    // background: #FAFAFA;
    padding: 5px 12px;

    // margin-bottom: 10px;

}

.infoContainer div {
    margin: 10px;
    font-weight: 600;
    font-size: 14px;
    color: #393939;
}

.infoContainer div span {
    min-width: 150px;
    display: inline-block;

    // background-color: #4DBD74;
    &:nth-child(1) {
        color: #606060;
    }
}

.exceptionContainer div {
    font-weight: 400 !important;
}

.textareaException {
    display: block;
    width: 100%;
    overflow: hidden;
    resize: none;
    min-height: 80px;
    line-height: 20px;
    align-items: flex-start;
    padding: 10px 12px;
    background: #FAFAFA;
    border-radius: 7px;
    border: 1px solid #CBCBCB
}