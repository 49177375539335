
.priority-tag{
    height: 24px;
    border-radius: 4px;
    font-family: "ProximaNovaSemibold";
    font-style: normal;
    font-weight: 500;
    font-size: 12px !important;
    padding: 4px 8px;
    box-sizing: border-box;

}

.HOT{
    background: #FEE8E9;
    border: 0.6px solid #DA2128;
    color: #DA2128 !important;
    width: 41px;
    
}
.COLD{
    background: #ECF9FF;
    border: 0.6px solid #0084A0;
    color: #0084A0 !important;
    width: 50px;
}
.WARM{
    background: #FBF4DB;
    border: 0.6px solid #AA945C;
    color: #AA945C !important;
    width: 54px;
}