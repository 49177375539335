.city-dropdown-container {
  width: 100%;
  margin: 0 10px;
}

.city-dropdown {
  &__indicator-separator {
    display: none;
  }

  &__control {
    height: 37px !important;
    min-height: 37px !important;
    border-radius: 0 !important;
  }
}

.city_trackRunner{
  background: #F6F6F6;
  max-width: 210px;
  border-radius: 8px;
margin-top: -5px;
  .city-dropdown__control.css-yk16xz-control{
    background-color: inherit;
    border: none;
  }
}

.city_controlTower{
  background: #F6F6F6;
  max-width: 210px;
  border-radius: 8px;
  .city-dropdown__control.css-yk16xz-control{
    background-color: inherit;
    border: none;
  }
  margin: 0;
}