.actionClass {
    background-color: #6FC978;
    padding: 5px 10px;
    color: #FFFFFF;
    border-radius: 8px;
}

.ctinspected {
    background-color: #FFE8D1;
    padding: 6px;
    border-radius: 9px;
    color: #B96716;
}

.auctioncompleted {
    background-color: #E4FFE8;
    padding: 6px;
    border-radius: 9px;
    color: #196834;
}

.auctionstarted {
    background-color: #FFE7E7;
    padding: 6px;
    border-radius: 9px;
    color: #DA2128
}

.requestButton {
    background-color: #55B7EF;
    padding: 5px;
    color: #FFFFFF;
    border-radius: 8px;
    width: 100%;
    max-width: 76px;
}

.rejectedButton {
    background-color: #F4364C;
    padding: 5px;
    color: #FFFFFF;
    border-radius: 8px;
    width: 100%;
    max-width: 76px;
}

.approvedButton {
    background-color: #6DBB7B;
    padding: 5px;
    color: #FFFFFF;
    border-radius: 8px;
    width: 100%;
    max-width: 76px;
}

.pendingButton {
    background-color: #BABABA;
    padding: 5px;
    color: #FFFFFF;
    border-radius: 8px;
    width: 100%;
    max-width: 76px;
}

.retriveButton {
    background-color: #262C69;
    padding: 5px;
    color: #FFFFFF;
    border-radius: 8px;
    width: 100%;
    max-width: 76px;
}

.soldButton {
    padding: 5px;
    width: 100%;
    max-width: 93px;
    background-color: #DAF2DD;
    border-radius: 10px;
    color: #196834;
}