.card {
  width: 100%;
  padding-bottom: 30px;
  height: 100%;
  border: none;
  background-color: transparent;

  &.card--not-full-height {
    height: auto;
  }
}

.card-body {
  padding: 15px;
  height: 100%;
  border-radius: 0px;

  .tat-title {
    font-size: 25px;
    font-family: 'ProximaNovaBold';
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    margin-bottom: 15px;

    &.blue {
      color: #147bc5;
    }

    &.orange {
      color: #fe7530;
    }

    &.light-red {
      color: #e76469;
    }

    &.red {
      color: #d92128;
    }

    span {
      font-family: 'ProximaNovaRegular';
      font-size: 20px;
      color: inherit;
    }
  }

  .tat-number {
    font-size: 50px;
    color: #949494;
    text-align: center;
    line-height: normal;
  }

  &.card-shadow {
    box-shadow: $shadow;
  }

  &.square-border {
    border-radius: 0px;
  }

  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  &.border-radius8{
    border-radius: 8px;
  }
}

.card__title {
  margin-bottom: 30px;
  text-transform: uppercase;
  position: relative;

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 13px;
  }
}

.squared-corner-theme {

  .card-body {
    border-radius: 0;
  }
}

.blocks-with-shadow-theme {

  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }
}