.boxContainer {
  background: #fffafa;
  margin-top: 40px;
  width: 406px;
  height: 100px;
  box-shadow: 0px 4px 4px 2px rgba(207, 207, 207, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  .titleBox {
    font-size: 16px;
    font-weight: 600;
  }
  .insideBoxContainer {
    color: #6d6d6d;
    display: flex;
    align-items: center;

    img {
      width: 30px;
      cursor: pointer;
    }

    div {
      display: flex;
      // flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      margin-left: 20px;
      width: 90px;
      height: 38px;
      background: #ffffff;
      border-radius: 34px;
      cursor: pointer;

      img {
        width: 22px;
      }
    }
  }
}

.rebidReasonSelector {
  margin-top: 20px;
  .rs-dropdown {
  }

  .main-dropDown-bid {
    > .rs-dropdown-toggle {
      display: flex;
      border-radius: 8px;
      min-width: 332px;
      // height: 16px;
      color: #a7a7a7;
      border: 1px solid #d2d2d2;
      &:hover {
      }
    }
  }

  .sub-dropDown-bid {
    > .rs-dropdown-toggle {
      display: flex;
      min-width: 332px;
      color: #000000;
      &:hover {
        background-color: white;
      }
    }
  }
  .rs-dropdown-menu {
    width: 332px;

    .rs-dropdown-menu {
      box-shadow: none;
      border-radius: none;
      padding-bottom: 10px;

      .rs-dropdown-item-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .rs-dropdown-item-disabled .rs-dropdown-item-content {
        opacity: 1;
        cursor: default;
      }
    }
  }
}

.inputRowRazor {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  align-items: center;
  width: 380px;
  margin: auto;
  font-weight: 500;
  font-size: 16px;
  color: #4A4A4A;

 

  label{
      font-size: 16px;
      height: 28px;
      padding: 6px 0px;
  }

  div{

      border: 1px solid #87AACA;
      // background: #;
      height: 28px;
      width: 160px;
      border-radius: 4px;
      overflow: hidden;
      display: flex;
      align-items: baseline;

      span{
          background-color: #CEE1F2;
          padding: 1px 8px;
          font-size: 16px;
          // border-right: 1px solid #87AACA;
      }

  .inputDisburse {
      align-items: flex-start;
      // padding: 10px 12px;
      width: 150px;
      height: 26px;
      background: #FAFAFA;
      border-radius: 7px;
      margin-left: 5px;
      border: none;
  
      &.availableBalance {
          border: none;
      }
      select{
        width: 150px;
      }
  }
}


&.totalAmount{
  div{
      background-color: #DAF2DD;
      .inputDisburse{
          background: none;
      }
      span{
          // background-color: none;
          border-right: 1px solid #87AACA;
      }
  }
}

&.holdAmount{
  div{
      background-color: #E8E8E8;
      .inputDisburse{
          background: none;
      }
  }

}

textarea{
  border: 1px solid #87AACA;
  border-radius: 4px;
  resize: none;
  outline: none;
  &:active{
    border: 1px solid #87AACA;
    outline: none;
  }
}

}

.release-type{
  div{
    span{
      padding: 0px;
      background-color: none;
      font-size: 0px;
    }
    select{
      height: 100%;
    }
  }
  border: 0px !important;
  font-size: 10px;
  & .MuiInput-input{
    padding-left: 10px;
    padding-top: 3px;
    color: #4A4A4A;
  }
  ::before{
    border: 0px !important;
  }
  ::after{
    border: 0px !important;
  }
  
}
